.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.progress-bar {
  width: 300px; /* Adjust width as needed */
  height: 10px;
  background: #ddd;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
}

.progress-fill {
  height: 100%;
  background: #4caf50;
  width: 0%;
  transition: width 0.1s ease-in-out;
}

.progress-text {
  font-size: 1.2rem;
  color: #fff;
}
