/* styles.css */
@keyframes gradient-border {
  0% {
    border-color: #ff0000;
  }
  25% {
    border-color: #00ff00;
  }
  50% {
    border-color: #050513;
  }
  75% {
    border-color: #1d061d;
  }
  100% {
    border-color: #ff0000;
  }
}

.animated-border {
  border-width: 2px;
  border-style: solid;
  animation: gradient-border 5s infinite;
}
