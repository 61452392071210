@tailwind base;
@tailwind components;
@tailwind utilities;


/* .zoomable-image:hover {
  transform: scale(1.2);  Adjust the scale as needed 
  transition: transform 0.5s ease;
} */

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  width: 100%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}


.zaddy-pay-btn {
  background: linear-gradient(90deg, #ff8a00, #e52e71);
  border: none;
  color: white;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  transition: all 0.3s ease-in-out;
}

.zaddy-pay-btn:hover {
  background: linear-gradient(90deg, #e52e71, #ff8a00);
  box-shadow: 0 0 15px rgba(255, 138, 0, 0.5), 0 0 20px rgba(229, 46, 113, 0.5);
}

.zoomable-image-container {
  position: relative;
  overflow: hidden;
  cursor: url('./assets/search.png'), auto;
}

.zoomable-image {
  transition: transform 0.5s ease;
  position: absolute;
  top: 0;
  left: 0;
}

.zoomable-image:hover {
  transform: scale(2); /* Adjust the scale as needed */
}

.zoomable-image-container:hover .zoomable-image {
  cursor: url('./assets/search.png'), auto;
}

/* src/index.css */
@media print {
  body {
    margin: 0;
    padding: 0;
    box-shadow: none;
  }
  .printable-receipt {
    width: 80mm;
    box-shadow: none;
  }
}

@media prints {
  body * {
    visibility: hidden;
  }
  
  .printable-receipt, .printable-receipt * {
    visibility: visible;
  }

  .printable-receipt {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
  }
}



@keyframes slideIn {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.animate-slideIn {
  animation: slideIn 1s forwards;
}


@layer base {
  html {
    @apply text-gray-800 cursor-default
  }
  body {
    @apply relative min-h-screen min-w-full dark:bg-gray-600 dark:text-white
  }
  .scrollbar::-webkit-scrollbar,
  html::-webkit-scrollbar {
    @apply w-2 bg-gray-300
  }
  .scrollbar::-webkit-scrollbar,
  html::-webkit-scrollbar {
    @apply w-2 bg-gray-300
  }
  .small-scrollbar.scrollbar::-webkit-scrollbar {
    @apply w-1 h-1
  }
  .no-scrollbar.scrollbar::-webkit-scrollbar {
    @apply w-0 h-0
  }
  .scrollbar::-webkit-scrollbar {
    @apply rounded-full
  }
  .scrollbar::-webkit-scrollbar-thumb,
  html::-webkit-scrollbar-thumb {
    @apply rounded-full bg-gray-600
  }
  .card {
    @apply shadow-lg p-5 md:p-8 rounded-md border border-slate-300 dark:border-slate-500
  }
  textarea,
  select,
  input {
    @apply py-1 px-3 bg-transparent rounded-md border-2 border-gray-400 outline-none focus:border-gray-600
  }
  button {
    @apply  px-4 py-2 uppercase tracking-wide rounded-md active:scale-95
  }
  .app-container{
    @apply w-full max-w-[1800px] m-auto px-4
  }
}